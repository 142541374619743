var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading)?_c('v-container',{staticClass:"pa-16"},[_c('app-loading'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('p',[_vm._v("Verifying your account, please wait.")])])],1)],1):_c('v-container',{staticClass:"pa-4"},[(_vm.errorMessage.length)?_c('section',{staticClass:"pa-4"},[_c('app-error-notice',{attrs:{"message":_vm.errorMessage}})],1):_vm._e(),_c('section',[_c('h2',[_vm._v("Check your account")]),_c('p',[_vm._v(" Check we have the right details for you so we can confirm you are a Healthcare Professional and set up your account ")])]),_c('v-divider',{staticClass:"my-4"}),_c('section',{staticClass:"mb-4"},[_c('v-alert',{attrs:{"type":"info","elevation":"2","outlined":"","dense":""}},[_c('p',[_vm._v(" Your professional code may include your registration with either The General Medical Council, The Nursing and Midwifery Council or General Pharmaceutical Council. Please only include your registration number and not the GMC, NMC or GPhC prefix. ")])])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"First name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name*","name":"First name","outlined":"","error-messages":errors,"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last name*","name":"Last name","required":"","outlined":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Professional code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Professional code (don't include GMC, NMC or GPhC prefix)","outlined":""},model:{value:(_vm.professionalCode),callback:function ($$v) {_vm.professionalCode=$$v},expression:"professionalCode"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Post code","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Work post code*","outlined":""},model:{value:(_vm.postCode),callback:function ($$v) {_vm.postCode=$$v},expression:"postCode"}})]}}],null,true)}),_c('section',[_c('p',[_vm._v("Required fields are marked with an asterisk *")])]),_c('v-divider')],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"accent","rounded":"","type":"submit","large":"","block":""}},[_vm._v("Submit")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }