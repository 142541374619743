







































































































































import Vue from "vue";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { HealthCareSpecialities } from "@/providers/HealthCareSpecialities";
import {
  AddressesClient,
  ApiException,
  UserAccountSetupWorkInformationCommand,
} from "@/clients/clients";
import { extend } from "vee-validate";
import { required_if } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import AppLoading from "@/components/layout/AppLoading.vue";
import AppErrorNotice from "@/components/indicators/AppErrorNotice.vue";
import AppAddressAutoComplete from "@/components/addresses/AppAddressAutoComplete.vue";
import AppLoadingSpinner from "@/components/indicators/AppLoadingSpinner.vue";
import { getAccessToken } from "@/services/auth/aadAuthService";

setInteractionMode("eager");

const addressValidationMessage =
  "{_field_} can not be empty when entering your address";
extend("required_if_streetAddress", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_streetAddress2", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_city", {
  ...required_if,
  message: addressValidationMessage,
});
extend("required_if_postCode", {
  ...required_if,
  message: addressValidationMessage,
});

export default Vue.extend({
  name: "AccountSetupFormWorkInformation",
  components: {
    AppLoadingSpinner,
    AppAddressAutoComplete,
    AppErrorNotice,
    AppLoading,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    form: new UserAccountSetupWorkInformationCommand(),
    errorMessage: "",
    loading: false,
    showAddressFields: false,
    addressLoading: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
    specialities(): string[] {
      return HealthCareSpecialities;
    },
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    ...mapActions("UserStore", ["accountSetupWorkInformation"]),
    init(): void {
      if (this.user) {
        const data = new UserAccountSetupWorkInformationCommand();
        data.init(this.user);
        this.form = Object.assign({}, this.form, data);
      }
    },
    async onSubmit(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.accountSetupWorkInformation(this.form);
        this.$emit("next");
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateAddress(val: number | string): Promise<void> {
      this.showAddressFields = true;
      if (val === 0) return;
      this.addressLoading = true;
      this.errorMessage = "";
      try {
        const accessToken: string = await getAccessToken();
        const client = this.$httpClientFactory(AddressesClient, accessToken);
        const response = await client.getFullAddress(val.toString());
        if (response.result) {
          this.$set(this.form, "streetAddress", response.result.line1);
          this.$set(this.form, "streetAddress2", response.result.line2);
          this.$set(this.form, "city", response.result.postTown);
          this.$set(this.form, "postCode", response.result.postcode);
        }
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.addressLoading = false;
      }
    },
  },
});
