























































































































































































import Vue from "vue";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { HealthCareInterests } from "@/providers/HealthCareInterests";
import { required } from "vee-validate/dist/rules";
import {
  ApiException,
  UserAccountSetupBasicInformationCommand,
} from "@/clients/clients";
import { mapActions, mapGetters } from "vuex";
import AppErrorNotice from "@/components/indicators/AppErrorNotice.vue";
import AppLoading from "@/components/layout/AppLoading.vue";

setInteractionMode("eager");

extend("confirm_hcp", {
  ...required,
  message: "Please confirm that you are a healthcare provider",
});

extend("required_field", {
  ...required,
  message: "{_field_} can not be empty",
});

export default Vue.extend({
  name: "AccountSetupFormBasicInformation",
  components: {
    AppLoading,
    AppErrorNotice,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    form: new UserAccountSetupBasicInformationCommand(),
    errorMessage: "",
    loading: false,
  }),
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  computed: {
    ...mapGetters("UserStore", ["user"]),
    areasOfInterest(): string[] {
      return HealthCareInterests;
    },
  },
  methods: {
    ...mapActions("UserStore", ["accountSetupBasicInformation"]),
    init(): void {
      if (this.user) {
        const data = new UserAccountSetupBasicInformationCommand();
        data.init(this.user);
        this.form = Object.assign({}, this.form, data);
      }
    },
    async onSubmit(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.accountSetupBasicInformation(this.form);
        this.$emit("next");
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
