










































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import AppLoading from "@/components/layout/AppLoading.vue";
import { ApiException, UserAccountVerifyCommand } from "@/clients/clients";
import AppErrorNotice from "@/components/indicators/AppErrorNotice.vue";

setInteractionMode("eager");
const verifyValidationMessage =
  "You must include either a valid post code or your professional code.";

extend("require_validation_info", {
  ...required,
  message: verifyValidationMessage,
});



export default Vue.extend({
  name: "AccountSetupFormVerify",
  components: {
    AppErrorNotice,
    AppLoading,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    firstName: "",
    lastName: "",
    professionalCode: "",
    postCode: "",
    errorMessage: "",
    loading: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    ...mapActions("UserStore", ["verifyAccount"]),
    init(): void {
      if (this.user) {
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.professionalCode = this.user.professionalCode;
        this.postCode = this.user.postCode;
      }
    },
    async onSubmit(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      try {
        const form = new UserAccountVerifyCommand();
        form.init({
          firstName: this.firstName,
          lastName: this.lastName,
          postCode: this.postCode,
          professionalCode: this.professionalCode,
        });
        await this.verifyAccount(form);
        await this.$router.push({ name: "AccountSetupVerify" });
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    user: {
      handler: function () {
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.professionalCode = this.user.professionalCode;
        this.postCode = this.user.postCode;
      },
      deep: true,
    },
  },
});
