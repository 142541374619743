var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading)?_c('v-container',{staticClass:"pa-16"},[_c('app-loading')],1):_c('v-container',{staticClass:"pa-4"},[(_vm.errorMessage.length)?_c('section',{staticClass:"pa-4"},[_c('app-error-notice',{attrs:{"message":_vm.errorMessage}})],1):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('section',{staticClass:"mb-4"},[_c('h2',{staticClass:"subtitle-1"},[_vm._v("About your work")])]),_c('section',[_c('validation-provider',{attrs:{"name":"First name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Job Title/Profession*","outlined":""},model:{value:(_vm.form.profession),callback:function ($$v) {_vm.$set(_vm.form, "profession", $$v)},expression:"form.profession"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"First name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Hospital/Practice Name*","outlined":""},model:{value:(_vm.form.organisationName),callback:function ($$v) {_vm.$set(_vm.form, "organisationName", $$v)},expression:"form.organisationName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Department","outlined":""},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}})],1),_c('section',[_c('p',[_vm._v("Required fields are marked with an asterisk *")])]),_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[_c('section',{staticClass:"mb-4"},[_c('h2',{staticClass:"subtitle-1"},[_vm._v("Work address")])]),_c('section',{staticClass:"my-4"},[_c('app-address-auto-complete',{on:{"change":_vm.updateAddress}})],1),(_vm.addressLoading)?_c('section',[_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('app-loading-spinner')],1)],1)],1):_vm._e(),(_vm.showAddressFields && !_vm.addressLoading)?_c('section',[_c('validation-provider',{attrs:{"vid":"streetAddress","rules":"required_if_streetAddress2:streetAddress2|required_if_city:city|required_if_postCode:postCode","name":"1st line of address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"1st line of address","outlined":"","error-messages":errors},model:{value:(_vm.form.streetAddress),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress", $$v)},expression:"form.streetAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"streetAddress2","name":"2nd line of address"}},[_c('v-text-field',{attrs:{"label":"2nd line of address","outlined":""},model:{value:(_vm.form.streetAddress2),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress2", $$v)},expression:"form.streetAddress2"}})],1),_c('validation-provider',{attrs:{"vid":"city","rules":"required_if_streetAddress:streetAddress|required_if_streetAddress2:streetAddress2|required_if_postCode:postCode","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"City","outlined":"","error-messages":errors},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Post code","vid":"postCode","rules":"required_if_streetAddress:streetAddress|required_if_streetAddress2:streetAddress2|required_if_city:city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Post code","outlined":"","error-messages":errors},model:{value:(_vm.form.postCode),callback:function ($$v) {_vm.$set(_vm.form, "postCode", $$v)},expression:"form.postCode"}})]}}],null,true)})],1):_vm._e(),_c('v-divider')],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"accent","rounded":"","type":"submit","large":"","block":""}},[_vm._v("Submit ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }