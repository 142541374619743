





























import Vue from "vue";
import AccountSetupFormBasicInformation from "@/components/accounts/forms/AccountSetupFormBasicInformation.vue";
import AccountSetupFormWorkInformation from "@/components/accounts/forms/AccountSetupFormWorkInformation.vue";
import AccountSetupFormVerify from "@/components/accounts/forms/AccountSetupFormVerify.vue";
import AppPage from "@/components/layout/page/AppPage.vue";

export default Vue.extend({
  name: "AccountSetupView",
  components: {
    AppPage,
    AccountSetupFormVerify,
    AccountSetupFormWorkInformation,
    AccountSetupFormBasicInformation,
  },
  data: () => ({
    step: 1,
  }),
});
